<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-center">
          <div
            v-for="property in properties"
            :key="property._id"
            class="w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"
          >
            <div class="bg-white shadow-xl rounded-lg overflow-hidden">
              <router-link
                :to="
                  '/accommodations/' +
                    property.name
                      .toLowerCase()
                      .split(' ')
                      .join('-')
                "
              >
                <div
                  class="bg-cover bg-center h-56 p-4"
                  :style="{
                    'background-image': `url(${$customConfig.api_host}/uploads/${property.featuredImage})`
                  }"
                >
                  <div class="flex justify-end">
                    <img src="/img/watermark.png" width="60px" />
                  </div>
                </div>
              </router-link>
              <div class="p-4">
                <p
                  class="uppercase tracking-wide text-sm font-bold text-gray-700"
                >
                  <i
                    v-for="n in property.starRating"
                    :key="n"
                    class="text-yellow-400 fas fa-star"
                  ></i>
                </p>
                <p class="text-2xl font-bold text-gray-900">
                  <router-link
                    :to="
                      '/accommodations/' +
                        property.name
                          .toLowerCase()
                          .split(' ')
                          .join('-')
                    "
                  >
                    {{ property.name }}
                  </router-link>
                </p>
                <p class="text-gray-700 text-sm">
                  {{ property.location }}
                </p>
              </div>

              <div class="px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100">
                <div
                  class="text-xs uppercase font-bold text-gray-600 tracking-wide"
                ></div>
                <div class="flex items-center pt-2">
                  <div class="w-full justify-center">
                    <router-link
                      :to="
                        '/accommodations/' +
                          property.name
                            .toLowerCase()
                            .split(' ')
                            .join('-')
                      "
                      ><button
                        class="bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      >
                        <span>REQUEST QUOTE</span>
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="totalPages > 1" class="my-6 flex justify-center">
      <button
        :class="{
          'bg-primary': canGoPreviousPage,
          'bg-gray-300': !canGoPreviousPage,
          'cursor-not-allowed': !canGoPreviousPage
        }"
        :disabled="!canGoPreviousPage"
        @click="previousPage"
        class="btn text-white"
      >
        Previous
      </button>
      <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :class="{
          'bg-primary': canGoNextPage,
          'bg-gray-300': !canGoNextPage,
          'cursor-not-allowed': !canGoNextPage
        }"
        :disabled="!canGoNextPage"
        @click="nextPage"
        class="btn text-white"
      >
        Next
      </button>
    </div>

    <!--Container 3 -->
    <div class="w-full mx-auto pt-2 bg-white m-20">
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-wrap">
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Best Offers And Deals Guarantee
                </h2>
              </div>
              <p>
                Our Best Deals Guarantee assures you receive the best rates
                after you book through us. Dedication to quality, understanding
                the needs of a customer and extensive focus on even the minutest
                of the details have been the biggest strengths for the company.
              </p>
            </div>
          </div>

          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-200">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Your Informations Are Highly Secure
                </h2>
              </div>
              <p>
                Most secured and latest technology available such as SSL and
                CloudFlare Security is protecting all the information that we
                receive through our website.
              </p>
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Highly Experienced Travel agent
                </h2>
              </div>
              <p>
                Our parent company Nalafalhu Holidays Pvt Ltd, has strived to
                serve in the best possible fashion to both their partners and
                their customers, alike, since their inception for more than 20
                years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";

export default {
  name: "AccommodationsPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchBar
  }
};
</script>

<style scoped></style>
