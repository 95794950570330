var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HeaderLayout'),_c('SearchBar'),_c('div',{staticStyle:{"clear":"both","display":"block","height":"0px"}}),_c('div',{staticClass:"w-full mx-auto pt-0 bg-white"},[_c('div',{staticClass:"max-w-7xl mx-auto px-4 sm:px-6"},[_c('div',{staticClass:"flex flex-wrap items-center justify-center"},_vm._l((_vm.properties),function(property){return _c('div',{key:property._id,staticClass:"w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"},[_c('div',{staticClass:"bg-white shadow-xl rounded-lg overflow-hidden"},[_c('router-link',{attrs:{"to":'/accommodations/' +
                  property.name
                    .toLowerCase()
                    .split(' ')
                    .join('-')}},[_c('div',{staticClass:"bg-cover bg-center h-56 p-4",style:({
                  'background-image': ("url(" + (_vm.$customConfig.api_host) + "/uploads/" + (property.featuredImage) + ")")
                })},[_c('div',{staticClass:"flex justify-end"},[_c('img',{attrs:{"src":"/img/watermark.png","width":"60px"}})])])]),_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"uppercase tracking-wide text-sm font-bold text-gray-700"},_vm._l((property.starRating),function(n){return _c('i',{key:n,staticClass:"text-yellow-400 fas fa-star"})}),0),_c('p',{staticClass:"text-2xl font-bold text-gray-900"},[_c('router-link',{attrs:{"to":'/accommodations/' +
                      property.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}},[_vm._v(" "+_vm._s(property.name)+" ")])],1),_c('p',{staticClass:"text-gray-700 text-sm"},[_vm._v(" "+_vm._s(property.location)+" ")])]),_c('div',{staticClass:"px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100"},[_c('div',{staticClass:"text-xs uppercase font-bold text-gray-600 tracking-wide"}),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"w-full justify-center"},[_c('router-link',{attrs:{"to":'/accommodations/' +
                        property.name
                          .toLowerCase()
                          .split(' ')
                          .join('-')}},[_c('button',{staticClass:"bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"},[_c('span',[_vm._v("REQUEST QUOTE")])])])],1)])])],1)])}),0)])]),(_vm.totalPages > 1)?_c('div',{staticClass:"my-6 flex justify-center"},[_c('button',{staticClass:"btn text-white",class:{
        'bg-primary': _vm.canGoPreviousPage,
        'bg-gray-300': !_vm.canGoPreviousPage,
        'cursor-not-allowed': !_vm.canGoPreviousPage
      },attrs:{"disabled":!_vm.canGoPreviousPage},on:{"click":_vm.previousPage}},[_vm._v(" Previous ")]),_c('span',{staticClass:"mx-3"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages))]),_c('button',{staticClass:"btn text-white",class:{
        'bg-primary': _vm.canGoNextPage,
        'bg-gray-300': !_vm.canGoNextPage,
        'cursor-not-allowed': !_vm.canGoNextPage
      },attrs:{"disabled":!_vm.canGoNextPage},on:{"click":_vm.nextPage}},[_vm._v(" Next ")])]):_vm._e(),_vm._m(0),_c('FooterLayout')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full mx-auto pt-2 bg-white m-20"},[_c('div',{staticClass:"max-w-6xl mx-auto"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Best Offers And Deals Guarantee ")])]),_c('p',[_vm._v(" Our Best Deals Guarantee assures you receive the best rates after you book through us. Dedication to quality, understanding the needs of a customer and extensive focus on even the minutest of the details have been the biggest strengths for the company. ")])])]),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-200"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Your Informations Are Highly Secure ")])]),_c('p',[_vm._v(" Most secured and latest technology available such as SSL and CloudFlare Security is protecting all the information that we receive through our website. ")])])]),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Highly Experienced Travel agent ")])]),_c('p',[_vm._v(" Our parent company Nalafalhu Holidays Pvt Ltd, has strived to serve in the best possible fashion to both their partners and their customers, alike, since their inception for more than 20 years. ")])])])])])])}]

export { render, staticRenderFns }